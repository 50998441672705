body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Quicksand', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.edcar_letter {
  font-size: 180px;
}

.placeholderText::placeholder {
  color: #C4C4C4;
  font-size: 1.5vw;
}

.placeholderTextStripe::placeholder {
  color: #848484;
  font-size: 15px
}

@media only screen and (max-width: 991px) {
  .hide_if_mobile_screen {
    display: none;
  }
}

@media only screen and (min-width: 991px) {
  .hide_if_not_mobile_screen {
    display: none;
  }

  .header_font_size_drop_down {
    font-size: 1.6vw;
    /* width: 100px !important; */
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-radius: 0px;
    background: #3CB4AA;
    color: white;
  }
}

@media only screen and (max-width: 991px) {
  .show_if_mobile_screen {
    color: rgb(60, 180, 170);
  }

  .mobile_font_size {
    font-size: 1.5vw
  }

  /* .header_font_size_drop_down {
    font-size: 17px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-radius: 0px;
    background: #3CB4AA;
    color: white;
  } */
}

@media only screen and (max-width: 991px) {
  .mobile_location_display_top_padding {
    padding-top: 1%;
  }
}

@media only screen and (max-width: 991px) {
  .select_your_location_display {
    color: black;
    padding-bottom: 2%;
  }
}

.qr-input {
  width: 100%;
  padding: 0.5rem 0.8rem;
  border-radius: var(--space);
  border: 1px solid #ccc;
  font-size: 1.2rem;
  outline: 0;
  transition: all 0.36s ease-in-out;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.generate-qr-btn {
  background: #3EB5AB;
  border: 1px solid #3EB5AB;
  border-radius: 20px;
  color: white;
}

.download-qr-btn {
  background: #FCC816;
  border: 1px solid #FCC816;
  border-radius: 20px;
  color: white;
}



/* select {
  font-size: 17px;
} */

/* @media only screen and (max-width: 850px) {
  .hide_if_mobile_screen {
    display: none;
  }
}

@media only screen and (min-width: 851px) {
  .hide_if_not_mobile_screen {
    display: none;
  }
}

@media only screen and (max-width: 850px) {
  .show_if_mobile_screen {
    color: rgb(60, 180, 170);
  }
}

@media only screen and (max-width: 850px) {
  .mobile_location_display_top_padding {
    padding-top: 1%;
  }
}

@media only screen and (max-width: 850px) {
  .select_your_location_display {
    color: black;
    padding-bottom: 2%;
  }
} */